<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0" y="0" width="21" height="20.509" viewBox="0, 0, 21, 20.509"
  >
    <g id="Layer_1">
      <path
        d="M1.99,3.873 C2.307,3.349 4.05,1.444 5.293,1.501
        C5.665,1.532 5.994,1.757 6.261,2.018 C6.874,2.617 8.629,4.881 8.729,5.358
        C8.971,6.526 7.578,7.2 8.005,8.378 C9.091,11.037 10.963,12.909 13.623,13.994
        C14.8,14.421 15.474,13.028 16.643,13.271 C17.118,13.371 19.384,15.126 19.983,15.739
        C20.243,16.005 20.468,16.335 20.499,16.707 C20.545,18.016 18.522,19.783 18.128,20.009
        C17.197,20.675 15.983,20.663 14.504,19.975 C10.374,18.257 3.774,11.782 2.024,7.497
        C1.355,6.025 1.308,4.803 1.99,3.873 z"
        fill-opacity="0"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Call',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
