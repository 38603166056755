

















































































import { mapActions, mapGetters } from 'vuex';
import Profile from '@/assets/icons/svg/Profile.vue';
import Call from '@/assets/icons/svg/Call.vue';
import Home from '@/assets/icons/svg/Home.vue';
import Heart from '@/assets/icons/svg/Heart.vue';
import Location from '@/assets/icons/svg/Location.vue';
import Bag from '@/assets/icons/svg/Bag.vue';
import Download from '@/assets/icons/svg/Download.vue';

export default {
  name: 'ProfileCard',
  components: {
    Profile,
    Call,
    Home,
    Heart,
    Location,
    Bag,
    Download,
  },
  props: {
    color: {
      type: String,
      default: 'background',
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', [
      'onBoardingCompleted',
    ]),
    appTheme() {
      if (this.$vuetify.theme.dark) {
        return 'dark';
      }
      return 'light';
    },
  },
  methods: {
    ...mapActions('onboarding', [
      'setOnboardingModal',
    ]),
    navigateTo({ path, action, externalPath }) {
      if (action === 'darkMode') {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        return;
      }
      if (externalPath) {
        window.open(externalPath, '_blank');
      }
      if (path.length > 0) {
        if (path === '/settings/knowledge-experience' && !this.onBoardingCompleted) {
          this.setOnboardingModal(true);
          return;
        }
        this.$router.push(path);
      }
    },
  },
};
