<template>
  <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.58272" cy="6.27803" r="4.77803"
    :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
    d="M1.00002 17.7035C0.998732 17.3676 1.07385 17.0358 1.2197 16.7333
    C1.67736 15.8179 2.96798 15.3328 4.03892 15.1131
    C4.81128 14.9483 5.59431 14.8382 6.38217 14.783
    C7.84084 14.6555 9.30793 14.6555 10.7666 14.7836
    C11.5544 14.8388 12.3374 14.9489 13.1099 15.1131
    C14.1808 15.3328 15.4714 15.7722 15.9291 16.7333
    C16.2224 17.3501 16.2224 18.0661 15.9291 18.6829
    C15.4714 19.644 14.1808 20.0834 13.1099 20.2939
    C12.3384 20.4655 11.5551 20.5788 10.7666 20.6326
    C9.57937 20.7332 8.38659 20.7516 7.19681 20.6875
    C6.92221 20.6875 6.65677 20.6875 6.38217 20.6326
    C5.59663 20.5794 4.81632 20.4662 4.04807 20.2939
    C2.96798 20.0834 1.68652 19.644 1.2197 18.6829
    C1.0746 18.3768 0.999552 18.0422 1.00002 17.7035Z"
    :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Profile',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
