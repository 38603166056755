<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7729 9.30504V6.27304
    C15.7729 4.18904 14.0839 2.50004 12.0009 2.50004
    C9.91694 2.49104 8.21994 4.17204 8.21094 6.25604V6.27304V9.30504"
    :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
    d="M16.7422 21.0004H7.25778
    C4.90569 21.0004 3 19.0954 3 16.7454V11.2294
    C3 8.87937 4.90569 6.97437 7.25778 6.97437H16.7422
    C19.0943 6.97437 21 8.87937 21 11.2294V16.7454
    C21 19.0954 19.0943 21.0004 16.7422 21.0004Z"
    :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Bag',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
