<template>
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
    d="M10.761 18.8538C8.5904 17.5179 6.57111 15.9456 4.73929 14.1652
    C3.45144 12.8829 2.47101 11.3198 1.8731 9.59539
    C0.797144 6.25031 2.05393 2.42083 5.57112 1.28752
    C7.41961 0.692435 9.43845 1.03255 10.9961 2.20148
    C12.5543 1.03398 14.5725 0.693978 16.4211 1.28752
    C19.9383 2.42083 21.2041 6.25031 20.1281 9.59539
    C19.5302 11.3198 18.5498 12.8829 17.2619 14.1652
    C15.4301 15.9456 13.4108 17.5179 11.2402 18.8538L11.0051 19L10.761 18.8538Z"
    :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Heart',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
