<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22V9" :stroke="color" stroke-width="1.5"
    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.0999 19.3L11.9999 21.9L8.8999 19.3"
    :stroke="color" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 14.8H5.4C3.5 14.8 2 13.3 2 11.4
    C2 9.5 3.5 8 5.4 8C5.8 8 6.1 8.1 6.4 8.2
    C6.3 7.8 6.3 7.3 6.3 6.9C6.3 4.2 8.4 2 11.1 2
    C13.3 2 15.2 3.5 15.8 5.5C16.3 5.4 16.7 5.3 17.3 5.3
    C19.9 5.3 22 7.4 22 10C22 12.6 19.9 14.8 17.2 14.8H16"
    :stroke="color" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Download',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
