<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.15722 20.7714V17.7047
    C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671
    C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809
    C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271
    C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784
    C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853
    C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256
    C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618
    C2.5 20.4607 4.05488 22 5.97291 22H7.89696
    C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
    :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Home',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
